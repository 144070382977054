/* assets/styles/app.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #5193d9; /* Bleu */
    --secondary-color: #cab601; /* Jaune */
    --danger-color: #e3342f; /* Rouge */
}


.icon {
    display: inline-block;
}

h2 {
    font-weight: bold;
}